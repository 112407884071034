import React from 'react';

function IframeComponent() {

  const params = new URLSearchParams(window.location.search);
  const param = params.get('uid')

  if (param !== '01ae422b-5dcc-4dc0-ba0f-d6654ab97e49') {
    return (
        <div>
          <h3>Invalid Survey</h3>
          <br>
          </br>
          <p>Contact support@junkshon.com</p>
        </div>
    )

  }

  return (
    <div>
      <iframe 
        title="Junkshon App Survey" 
        src="https://surveyjs.io/published?id=f65bcb08-7279-4a9e-8f5f-995f55b1ef5a" 
        width="100%" 
        height="1000px"
        style={{border: "none"}}> 
      </iframe>
    </div>
  );
}

export default IframeComponent;
